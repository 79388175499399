// @flow

import React from "react"
import Container from "./Container"
import HTMLText from "./HTMLText"

type Props = {
  title: string,
  text: string,
  pdf?: mixed,
  pdfTitle?: string,
}

const Legal = ({ title, text, pdf, pdfTitle }: Props) => {
  return (
    <Container>
      <div className="grid grid-cols-4 pt-8 pb-40 md:grid-cols-12 md:pt-4 md:pb-48">
        <h1 className="col-span-4 pb-12 leading-none md:col-start-4 md:col-span-6 font-secondary text-bigger4 md:text-big4 md:pb-12">
          {title}
        </h1>
        <HTMLText
          className="col-span-4 md:col-start-4 md:col-span-8 font-primary text-small1"
          text={text}
        />

        {pdf && pdfTitle && pdf?.url ? (
          <div className="col-span-4 md:col-start-4 md:col-span-8 font-primary text-small1 html-container markdown customList">
            <a target="_blank" href={pdf.url.split("?")[0]} rel="noreferrer">
              {pdfTitle}
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </Container>
  )
}

export default Legal
